import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2><strong parentName="h2">{`Background`}</strong></h2>
    <p>{`Mechanics in wiritng is lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`}</p>
    <h2><strong parentName="h2">{`Currency`}</strong></h2>
    <p>{`Make it easier for readers to see transaction detail, invoice, SKU, banner, description, etc.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="1:1" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/currancy-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="1:1" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/currancy-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2><strong parentName="h2">{`Date & Time`}</strong></h2>
    <p>{`List of date and time formats for specific cases in our products.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="1:1" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/datetime-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="1:1" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/datetime-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2><strong parentName="h2">{`Numbers`}</strong></h2>
    <p>{`Except for a few basic rules, spelling out numbers is largely a matter of writers’ preference. But, we have our own guidelines to maintain consistency.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/number-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/number-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2><strong parentName="h2">{`Phone Number`}</strong></h2>
    <p>{`Follow our guideline to write phone numbers to maintain consistency.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/phone-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/phone-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2><strong parentName="h2">{`Measurements`}</strong></h2>
    <p>{`Rules for writing units of measurement vary according to our guideline.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/measurements-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/measurements-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2><strong parentName="h2">{`Temperature, Coordinate, and Angle`}</strong></h2>
    <p>{`Guide to write temperature, coordinate, and angle.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/suhu-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/suhu-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2><strong parentName="h2">{`File Extensions`}</strong></h2>
    <p>{`Guide to write temperature, coordinate, and angle.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/file-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/file-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2><strong parentName="h2">{`Institution`}</strong></h2>
    <p>{`A guide on how to write several government institutions or organizations name on our products.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="1:1" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/institution-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="1:1" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/institution-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2><strong parentName="h2">{`Cities & Countries`}</strong></h2>
    <p>{`The specific name of a place is capitalized.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/cities-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/cities-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2><strong parentName="h2">{`URLs`}</strong></h2>
    <p>{`A guide to write URLs on our products.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/urls-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/mechanics/urls-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      